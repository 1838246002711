import React, { useEffect, useRef } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react';

import './../custom-fonts.css';
import './MusicPage.css';

import PlayMusic from './../images/PlayMusic.png';
import License from './../images/License.png';

const handleClick = () => {
    alert('Music not available for licensing. Stay tuned!');
};

export default function MusicPage() {
    return (
        <VStack className="Music-header">
            <Box fontSize='vlg' fontFamily='FFPraterSansProRegular'>
                Music track
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                Waltz No.1
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                Lullaby No.1
            </Box>
            <Divider />
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                <Image size='tiny' src={License} onClick={handleClick} style={{ cursor: 'pointer' }} /> 
                Inquire about licensing
            </Box>
        </VStack>
    )
};