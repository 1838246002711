import React, { useEffect, useRef } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react';

import './../custom-fonts.css';
import './ElinaPage.css';

import Storybook from './../images/Storybook.png';

const handleClick = () => {
    alert('Story not available yet. Stay tuned!');
};

export default function AboutPage() {
    return (
        <VStack className="Elina-header">
            <Box fontSize='huge' fontFamily='LittleLove' color='#dca1a1'>
                Elina
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                "As we innocently play, I soon know
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                That what Elina wants is to grow tall
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                To her, it seems like time just moves too slow
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                Quite happily, I soon start to recall
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                Myself at her age, some long time ago
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                And realize... All I want is to grow small."
            </Box>
            <Divider />
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                <Image size='small' src={Storybook} onClick={handleClick} style={{ cursor: 'pointer' }} /> 
                Read the Synopsis
            </Box>
        </VStack>
    )
};