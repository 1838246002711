import React, { useEffect, useRef } from 'react';
import { Box, VStack } from '@chakra-ui/react';

import './../custom-fonts.css';
import './AboutPage.css';


export default function AboutPage() {
    return (
        <VStack className="About-header">
            <Box fontSize='vlg' fontFamily='FFPraterSansProRegular'>
                About
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                Polymaestro is a registered 501c(3) nonprofit located in the SF Bay Area.
            </Box>
            <Box pb={4} fontSize='19' fontFamily='FFPraterSansProRegular' textAlign='center'>
                Our mission is to create operas that inspire our community through the power of music and storytelling.
            </Box>
        </VStack>
    )
};