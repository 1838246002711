/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { InView } from 'react-intersection-observer';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react';

import './HomePage.css';

import ElinaPage from './ElinaPage';
import MusicPage from './MusicPage';
import AboutPage from './AboutPage';

import Opera from './../images/Opera.jpg';
import Elina from './../images/Elina.jpg';
import MusicNotes from './../images/MusicNotes.jpg';
import ComingSoon from './../images/ComingSoon.png';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      inverted
      content='Polymaestro'
      style={{
        fontFamily: 'FFPraterSansProRegular, Courier',
        fontSize: mobile ? '3em' : '6em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      inverted
      content='SF Bay Area Music and Opera Company'
      style={{
        fontFamily: 'FFPraterSansProRegular, Courier',
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <Button primary size='huge' fontFamily ='FFDaxProRegular' onClick={() => alert('Polymaestro will go live in 2025. Stay tuned!')}>
      Donate
    </Button>
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  state = { activeItem: 'home', fixed: false };

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView });

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { children } = this.props;
    const { fixed, activeItem } = this.state;

    return (
      <Media greaterThan='mobile'>
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            id="home"
            textAlign='center'
            style={{
              minHeight: 700,
              padding: '1em 0em',
              backgroundImage: `url(${Opera})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item
                  name="home"
                  href="#home"
                  active={activeItem === 'home'}
                  onClick={this.handleItemClick}
                >
                    <p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>
                        Home
                    </p>
                </Menu.Item>
                <Menu.Item
                  name="operas"
                  href="#operas"
                  active={activeItem === 'operas'}
                  onClick={this.handleItemClick}
                >
                    <p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>
                        Operas
                    </p>
                </Menu.Item>
                <Menu.Item
                  name="music"
                  href="#music"
                  active={activeItem === 'music'}
                  onClick={this.handleItemClick}
                >
                    <p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>
                        Music
                    </p>
                </Menu.Item>
                <Menu.Item
                  name="about"
                  href="#about"
                  active={activeItem === 'about'}
                  onClick={this.handleItemClick}
                >
                    <p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>
                        About
                    </p>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </InView>

        {children}
      </Media>
    );
  }
}


DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = { activeItem: 'home', sidebarOpened: false };

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  handleItemClick = (e, { name }) => this.setState({ activeItem: name, sidebarOpened: false });

  render() {
    const { children } = this.props;
    const { sidebarOpened, activeItem } = this.state;

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item
              name="home"
              href="#home"
              active={activeItem === 'home'}
              onClick={this.handleItemClick}
            >
              Home
            </Menu.Item>
            <Menu.Item
              name="operas"
              href="#operas"
              active={activeItem === 'operas'}
              onClick={this.handleItemClick}
            >
              Operas
            </Menu.Item>
            <Menu.Item
              name="music"
              href="#music"
              active={activeItem === 'music'}
              onClick={this.handleItemClick}
            >
              Music
            </Menu.Item>
            <Menu.Item
              name="about"
              href="#about"
              active={activeItem === 'about'}
              onClick={this.handleItemClick}
            >
              About
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              textAlign='center'
              style={{ minHeight: 350, backgroundImage: `url(${Opera})`, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
  }
}


MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer >{children}</DesktopContainer>
    <MobileContainer >{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomePage = () => (
  <ResponsiveContainer>
    <Divider />
    <Segment id="operas" style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={7}>
            <ElinaPage />
          </Grid.Column>
          <Grid.Column floated='right' width={6} >
            <Image size='small' src={ComingSoon} />
            <Image size='large' src={Elina} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment id="music" style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
        <Grid.Column width={7}>
            <MusicPage />
        </Grid.Column>
         <Grid.Column floated='right' width={6}>
            <Image size='large' src={MusicNotes} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment id="about" style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <AboutPage />
      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <List link inverted>
                <List.Item name="about" href="#about"><p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>About</p></List.Item>
                <List.Item as='a'><p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>Contact Us</p></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <List link inverted>
                <List.Item as='a'><p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>Music Licensing</p></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
                <p style={{ fontFamily: 'FFPraterSansProRegular, Courier' }}>
                    <span>&#169;</span> 2024 Polymaestro, All Rights Reserved.
                </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default HomePage